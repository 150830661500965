import React, { Fragment, useState } from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import './style.css';

const menus = [
    {
        id: 3,
        title: 'Un Solo País',
        sectionId: 'espacio', // Updated section ID
    },
    {
        id: 2,
        title: 'Acerca de Rosanna',
        sectionId: 'about', // Updated section ID
    },
    {
        id: 4,
        title: 'Propuestas',
        sectionId: 'propuestas', // Updated section ID
    },
    {
        id: 5,
        title: 'Contacto',
        sectionId: 'contacto', // Updated section ID
    }
];

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = (e, sectionId) => {
        e.preventDefault();
        const section = document.getElementById(sectionId);
        const navbarHeight = 84; // Adjust this value based on the actual height of your mobile navbar

        if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset; // Calculate the top position of the section
            const scrollToPosition = sectionPosition - navbarHeight; // Subtract the navbar height to offset the scroll

            // Custom smooth scrolling with reduced speed (adjust speedMultiplier for slower scroll)
            smoothScroll(scrollToPosition, 1000); // 1000ms for slower scroll

            setMenuState(false); // Close menu after clicking
        }
    };

    // Custom smooth scroll function
    const smoothScroll = (targetPosition, duration) => {
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        let startTime = null;

        const easeInOutCubic = (time, start, change, duration) => {
            time /= duration / 2;
            if (time < 1) return (change / 2) * time * time * time + start;
            time -= 2;
            return (change / 2) * (time * time * time + 2) + start;
        };

        const animation = (currentTime) => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
            window.scrollTo(0, run);

            if (timeElapsed < duration) requestAnimationFrame(animation);
        };

        requestAnimationFrame(animation);
    };

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                <NavLink
                                    className="active"
                                    to="/" // Keep this to use the NavLink but prevent default behavior
                                    onClick={(e) => ClickHandler(e, item.sectionId)} // Use the section scroll function
                                >
                                    {item.title}
                                </NavLink>
                            </ListItem>
                        )
                    })}
                </ul>
            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    );
};

export default MobileMenu;
