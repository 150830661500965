import cimg1 from '../images/campaign/img-1.jpg'
import cimg2 from '../images/campaign/img-2.jpg'
import cimg3 from '../images/campaign/img-3.jpg'
import cS2img1 from '../images/campaign/img-4.jpg'
import cS2img2 from '../images/campaign/img-5.jpg'
import cS2img3 from '../images/campaign/img-6.jpg'

import cSimg1 from '../images/campaign/single/img-1.jpg'
import cSimg2 from '../images/campaign/single/img-2.jpg'
import cSimg3 from '../images/campaign/single/img-3.jpg'



const Campaign = [
    {
       Id:'1',
       cImg:cimg1,
       cImg2:cS2img1,
       cSimg:cSimg1,
       thumb:'Propuesta #1',   
       title:'Seguridad y drogas: \n no hay libertad sin seguridad pública.',  
       slug:'Support-for-Womans',
       date:'July 1, 2023',
       time:'15:00 - 19:00',
       location:'85 Preston Rd. Inglewood',
       progress:'90',
       raised:'2000.00',
       goal:'3000.00',
    },
    {
       Id:'2',
       cImg:cimg2,
       cImg2:cS2img2,
       cSimg:cSimg2,
       thumb:'Propuesta #2',   
       title:'PYMES: el verdadero motor del país.', 
       slug:'Business-Earning',
       date:'July 2, 2023',
       time:'10:00 - 15:00',
       location:'85 Preston Rd. Inglewood',
       progress:'80',
       raised:'3000.00',
       goal:'4000.00',
    },

    
]

export default Campaign;