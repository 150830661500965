import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logoCabildo2Alt.png';
import { Toaster, toast } from 'react-hot-toast'; // Optional: if you want to give user feedback

const handleScrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};

const Footer = (props) => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const SubmitHandler = (e) => {
        e.preventDefault();

        if (!email) {
            toast.error('Por favor, ingrese un correo electrónico válido.');
            return;
        }

        // Send the email using the SMTP.js service
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "contacto@rosanna2701.uy", // Your email address
            Password: "CADCDCDBF41C2E4007865C32D47D110F66FC", // SMTP password
            To: 'hola@rosanna2701.uy',
            From: 'contacto@rosanna2701.uy', // Sender's email
            Subject: `Solicitud de lista 2701 de ${email}`,
            Body: `El correo ${email} solicitó una copia de la lista 2701 por correo.`,
            Port: 2525
        }).then((message) => {
            if (message === 'OK') {
                toast.success('Su solicitud fue enviada correctamente. Recibirá la lista en su correo.');
                setEmail(''); // Clear the input after success
            } else {
                toast.error('Ha ocurrido un error. Por favor intente nuevamente.');
            }
        }).catch((error) => {
            toast.error('Ha ocurrido un error. Por favor intente nuevamente.');
        });
    };

    return (
        <footer className="wpo-site-footer" style={{ fontFamily: 'Raleway' }}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={(e) => handleScrollToSection(e, 'home')} className="navbar-brand" to="/">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                                <ul className="info">
                                    <li>Teléfono: +598 92350353</li>
                                    <li>hola@rosanna2701.uy</li>
                                    <li>Montevideo, Uruguay</li>
                                </ul>
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/rosanna.deoliveramendez.9/" target="_blank" rel="noopener noreferrer">
                                                <i className="fi flaticon-facebook-app-symbol"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/rosannadeoliveramendez/" target="_blank" rel="noopener noreferrer">
                                                <i className="fi flaticon-instagram-1"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Links</h3>
                                </div>
                                <ul>
                                    <li><a href="#espacio" onClick={(e) => handleScrollToSection(e, 'espacio')}>Un Solo País</a></li>
                                    <li><a href="#about" onClick={(e) => handleScrollToSection(e, 'about')}>Acerca de Rosanna de Olivera</a></li>
                                    <li><a href="#propuestas" onClick={(e) => handleScrollToSection(e, 'propuestas')}>Propuestas</a></li>
                                    <li><a href="#contacto" onClick={(e) => handleScrollToSection(e, 'contacto')}>Contacto</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>¿Te gustaría recibir una lista?</h3>
                                </div>
                                <p>Dejanos tu contacto si querés recibir nuestra lista.</p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="input-1">
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Ingrese su email..." 
                                            required 
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit">Recibir lista</button>
                                    </div>
                                </form>
                                <Toaster position="bottom-left" reverseOrder={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-lg-6 col-md-12 col-12">
                            <ul>
                                <li>&copy; 2024 <a href="#home" onClick={(e) => handleScrollToSection(e, 'home')}>Un Solo País</a>. Todos los derechos reservados.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
