import React from 'react'
import sign from '../../images/signeture.png'
import { Link } from 'react-router-dom';

const About = (props) => {
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`} style={{paddingTop: '80px', paddingBottom: '80px'}}>
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt="" />
                                <div className="wpo-about-img-text">
                                    {/* <h4>1998</h4> */}

                                    {/* <div className="rotate-text">
                                        <span>W</span>
                                        <span>e</span>
                                        <span>A</span>
                                        <span>r</span>
                                        <span>e</span>
                                        <span>W</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>k</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>g</span>
                                        <span>F</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>Y</span>
                                        <span>o</span>
                                        <span>u</span>
                                        <span>S</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>c</span>
                                        <span>e</span>
                                    </div> */}
                                    {/* <div className="dots">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div> */}
                                    <div className="border-shape-1"></div>
                                    <div className="border-shape-2"></div>
                                    <div className="border-shape-3"></div>
                                </div>
                                <div className="about-shape">
                                    <div className="shape-1"></div>
                                    <div className="shape-2"></div>
                                    <div className="shape-3"></div>
                                    <div className="shape-4"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Acerca de Rosanna</span>
                                    <h2 style={{    fontFamily: 'Raleway', fontSize: '50px', lineHeight: '55px',
    fontWeight: 600}}>Rosanna de Olivera es una líder comprometida con la transformación de Uruguay.</h2>
                                </div>
                                <p style={{fontFamily: 'Raleway', fontWeight: 300, fontSize: '18px'}}>Con una trayectoria marcada por la <strong>transparencia, la eficiencia y la valentía,</strong> Rosanna ha demostrado su capacidad de gestión al frente del Instituto Nacional de Inclusión Social Adolescente (INISA), donde implementó reformas sustanciales que mejoraron significativamente las condiciones de rehabilitación y reinserción juvenil. </p>
                                <p style={{fontFamily: 'Raleway', fontWeight: 300, fontSize: '18px'}}>Su enfoque se basa en la <strong>planificación estratégica</strong>, el <strong>trabajo en equipo</strong> y la <strong>proximidad con la gente,</strong> siempre buscando un Uruguay más seguro, próspero y libre para todos. </p>
                                <div className="social">
                                    <ul>
                                        <li>
                                        <a  href="https://www.facebook.com/rosanna.deoliveramendez.9/" target="_blank" >
                                                <i className="fi flaticon-facebook-app-symbol"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/rosannadeoliveramendez/" target="_blank" >
                                                <i className="fi flaticon-instagram-1"></i>
                                            </a>
                                        </li>
                                  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;