import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Navbar from '../../components/Navbar/Navbar';
import Hero2 from '../../components/hero2/Hero2';
import Hero4 from '../../components/hero4/Hero4';
import About from '../../components/about/about';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import CampaignSection from '../../components/CampaignSectionAlt/CampaignSection';
import CampaignSectionS2 from '../../components/CampaignSectionS2/CampaignSectionS2';
import Testimonial from '../../components/Testimonial/Testimonial';
import FunFactS2 from '../../components/FunFactS2/FunFactS2';
import FunFactS2Alt from '../../components/FunFactS2Alt/FunFactS2';
import TeamSection from '../../components/TeamSection/TeamSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import InstagramSection from '../../components/InstagramSection/InstagramSection';
import Donors from '../../components/Donors/Donors';
import DonorsAlt from '../../components/DonorsAlt/Donors';
import Footer from '../../components/footer/Footer';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Contactpage from '../../components/Contactpage/Contactpage';
import abimg from '../../images/rossana1.png';
import wp from '../../images/rossana3.png';
import Logo from '../../images/logoCabildo2Alt.png';
import LogoMini from '../../images/logowhite.png';
import Hero3 from '../../components/hero3Alt/hero3';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// Define a simple fade-in motion variant
const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const HomePage2 = () => {
  return (
    <Fragment>
            <FloatingWhatsApp style={{ height: 50, width: 50, animation: "none !important" }}

 phoneNumber="+59892350353" accountName="Rosanna de Olivera" chatMessage="Hola! Somos el equipo de Rosanna. Cómo podemos ayudarte?" placeholder="Escribe tu consulta..." statusMessage="Te responderemos a la brevedad" avatar={wp}/>
      <Navbar hclass={'wpo-site-header-s1'} topbarNone={'topbar-none'} Logo={Logo} />

      {/* Replace AOS with Framer Motion */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <Hero4 />
      </motion.div>

      <motion.section
        id="espacio"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <Hero3 />
      </motion.section>

      <motion.section
        id="about"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <About abimg={abimg} abClass={'wpo-about-section-s2'} />
      </motion.section>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <Donors />
      </motion.div>

      <motion.section
        id="propuestas"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <CampaignSectionS2 />
      </motion.section>

      <DonorsAlt />

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <InstagramSection />
      </motion.div>

      <motion.section
        id="contacto"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true, amount: 0.1 }} // Trigger when 10% of the section is in view
      >
        <Contactpage />
      </motion.section>

      <Footer />
    </Fragment>
  );
};

export default HomePage2;
