import React from 'react';
import ContactForm from '../ContactFrom/ContactForm';

const Contactpage = () => {
    return (
        <div 
            className="wpo-contact-pg-section section-padding" 
            style={{
                backgroundColor: '#264F9A',
                paddingTop: '80px'
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12">
                        <div className="office-info">
                            <div className="row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                {/* Ensure columns are responsive */}
                                <div className="col col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="office-info-item" style={{
                                       background: 'white',
                                       boxShadow: '0px 5px 15px 0px rgb(26 27 73 / 59%)',
                                       borderRadius: '6px',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center', // Center horizontally
                                       justifyContent: 'center', // Center vertically
                                       height: '100%', // Make sure it takes the full height of the column
                                       textAlign: 'center' 
                                    }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-instagram"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Instagram</h2>
                                            <p style={{fontFamily: 'Raleway'}}>@rosannadeoliveramendez</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="office-info-item" style={{
                                           background: 'white',
                                           boxShadow: '0px 5px 15px 0px rgb(26 27 73 / 59%)',
                                           borderRadius: '6px',
                                           display: 'flex',
                                           flexDirection: 'column',
                                           alignItems: 'center', // Center horizontally
                                           justifyContent: 'center', // Center vertically
                                           height: '100%', // Make sure it takes the full height of the column
                                           textAlign: 'center' 
                                    }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email</h2>
                                            <p style={{fontFamily: 'Raleway'}}>hola@rosanna2701.uy</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="office-info-item" style={{
                                      background: 'white',
                                      boxShadow: '0px 5px 15px 0px rgb(26 27 73 / 59%)',
                                      borderRadius: '6px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center', // Center horizontally
                                      justifyContent: 'center', // Center vertically
                                      height: '100%', // Make sure it takes the full height of the column
                                      textAlign: 'center' 
                                    }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Whatsapp</h2>
                                            <p style={{fontFamily: 'Raleway'}}>+598 92350353</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row"
                            style={{
                                height: 'auto', // Ensure it adjusts for mobile
                                borderTop: '1px solid #ffffff12',
                                borderLeft: '1px solid #ffffff12',
                                borderBottom: '1px solid #ffffff12',
                                borderRadius: '12px',
                                backgroundColor: '#264F9A',
                                backgroundImage: 'url(/shape.png)', 
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundBlendMode: 'overlay',
                                margin: '20px 0' // Add some margin for spacing
                            }}
                        >
                            <div className="col-lg-5 col-md-12 col-12" style={{ marginTop: '20px' }}>
                                <div className="wpo-contact-title" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}>
                                    <div style={{
                                        margin: '10px 0px',
                                        display: 'inline-block',
                                        lineHeight: '1.2',
                                        fontFamily: '"Raleway", sans-serif'
                                    }}>
                                        <h2 className="centered-title" style={{
                                            color: 'white',
                                            marginBottom: 0,
                                            textAlign: 'left',
                                            fontSize: '45px',
                                            fontFamily: 'Raleway',
                                            fontWeight: 600,
                                            lineHeight: '55px'
                                        }}>
                                            Contactanos:
                                        </h2>
                                    </div>
                                    <p style={{ color: 'white', textAlign: 'left', fontFamily: 'Raleway' }}>
                                        Completá el formulario y te responderemos de inmediato.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-12">
                                <div className="wpo-contact-form-area" style={{
                                    background: 'white',
                                    boxShadow: '0px 5px 15px 0px rgb(26 27 73 / 59%)',
                                    borderRadius: '6px'
                                }}>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactpage;
