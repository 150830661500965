import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import ins1 from '../../images/instagram/1.jpg';
import ins2 from '../../images/instagram/2.jpg';
import ins3 from '../../images/instagram/3.jpg';
import ins4 from '../../images/instagram/4.jpg';
import ins5 from '../../images/instagram/5.jpg';
import ins6 from '../../images/instagram/6.jpg';

const InstagramArray = [
    { InsImg: ins1, link: 'https://www.instagram.com/reel/C7fJEQkuBfD/?igsh=MTRzbjFlZml2Z2M2OQ==' },
    { InsImg: ins2, link: 'https://www.instagram.com/reel/C_ds0QCp4oQ/?igsh=aWF4N2loOHp4YWho' },
    { InsImg: ins3, link: 'https://www.instagram.com/reel/C_3MJdyRpT6/?igsh=ZnFxcWMxY2oxNjJ4' },
    { InsImg: ins4, link: 'https://www.instagram.com/reel/C_1JLInyOxZ/?igsh=MTgxa2UycWhhdXpoYQ==' },
    { InsImg: ins5, link: 'https://www.instagram.com/p/C3fXBZBOpms/?igsh=MTV6djBzajllN2RsYg==' },
    { InsImg: ins6, link: 'https://www.instagram.com/p/C_8KMRYAQAx/?igsh=d2xxOW81bWx5bnM4' }
];

const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    centerMode: true,
    slidesToScroll: 1,
    centerPadding: '220px',
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerPadding: '0',
                infinite: true,
                centerMode: false,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        }
    ]
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const InstagramSection = () => {
    return (
        <section className="wpo-instagram-section" style={{ margin: '80px 0px' }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title" style={{ maxWidth: '657px' }}>

                            <h2 className="special-title" style={{
                                fontSize: '50px',
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                                lineHeight: '55px'
                            }}>Conocé las últimas noticias</h2>
                            <span className="special-subtitle" style={{ textTransform: 'none' }}>  Seguinos en instagram <a href="https://www.instagram.com/rosannadeoliveramendez/" style={{
                                fontFamily: '"Raleway", sans-serif',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '20px',
                                textTransform: 'lowercase'
                            }} className="special-subtitle" >@rosannadeoliveramendez </a></span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-instagram-container instagram-slider">
                            <Slider {...settings}>
                                {InstagramArray.map((instagram, ins) => (
                                    <div className="grid special-grid" key={ins}>
                                        <div className="wpo-instagram-item" style={{
                                            width: '300px',
                                            height: '300px',
                                            backgroundColor: 'white', // White background
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            overflow: 'hidden',
                                            position: 'relative' // Necessary for overlay
                                        }}>
                                            <a href={instagram.link} target="_blank"
                                            >
                                                <img
                                                    src={instagram.InsImg}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain' // Contain image to fit within 300x300
                                                    }}
                                                />
                                                {/* Blue Overlay */}
                                                <div style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(38, 79, 154, 0.3)', // Blue overlay
                                                    zIndex: 1, // Ensure it sits above the image
                                                }}></div>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InstagramSection;
