import React from 'react';
import { Link } from 'react-router-dom';
import shape from '../../images/campaign/shape.png'; // Path to your image
import DownloadIcon from '@material-ui/icons/CloudDownload';
import thumb1 from '../../images/rossana3.png';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Donors = () => {

    return (
        <section className="wpo-donors-section parallax-background">
            <div className="container full-width-container custom-mobile" style={{height: '100%'}} >
                <div className="wpo-donors-wrap banner-with-background-alt " style={{background: 'transparent',height: '100%', display: 'flex',
    alignItems: 'center'}}>
                    <div className="row align-items-center">
                        <div style={{paddingBottom: '40px',  display: 'flex', justifyContent: 'center'}}>
                            <h2 className="coraje" style={{width: '70%', fontSize: '45px', 
                          fontFamily: 'Raleway', lineHeight: '55px', color: 'white',
    textAlign: 'center'}}>
                                “Con el coraje para actuar y la visión para transformar, podemos devolverle a Uruguay su esencia.”
                            </h2>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="wpo-testimonial-text-btm" style={{
                                marginTop: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '5px',
                                paddingBottom: '5px'
                            }}>
                                <div style={{
                                    padding: '6px',
                                    background: '#fff',
                                    borderRadius: '50%',
                                    boxShadow: 'rgb(0 18 52 / 63%) 0px 2px 10px',
                                    marginRight: '18px'
                                }}>
                                    <img src={thumb1} alt="" style={{
                                        borderRadius: '50%',
                                        height: '80px'
                                    }} />
                                </div>
                                <div className="wpo-testimonial-text-btm-info">
                                    <h3 style={{color: 'white'}}>Rosanna de Olivera</h3>
                                    <span style={{fontFamily: 'Raleway'}}>Lista 2701</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Donors;
