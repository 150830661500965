import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import himg from '../../images/rossana2.png'
import logo from '../../images/USP_Vectores.png'
import icon1 from '../../images/slider/medal.svg'
import icon2 from '../../images/slider/stick-man.svg'

import cImg1 from '../../images/slider/client1.png'
import cImg2 from '../../images/slider/client2.png'
import cImg3 from '../../images/slider/client3.png'
import cImg4 from '../../images/slider/client4.png'

const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Hero3 = () => {
    return (
        <section className="static-hero-s2 static-hero-s2-alt" style={{ background: '#f5f3f3' }}>
            <div className="hero-container">
                <div className="hero-inner" style={{ height: '90%' }}>
                    <div className="container-fluid">
                        <div className="hero-content special-2" style={{ paddingLeft: 0 }}>
                            <div data-swiper-parallax="300" className="slide-title" >
                                {/* <h2 style={{
                                    margin: '0px', color: '#264F9A',
                                    padding: '0px', lineHeight: '65px', fontSize: '60px'
                                }}>UN SOLO <span style={{
                                    marginTop: '0px',
                                    paddingTop: '0px', fontWeight: 600, lineHeight: '45px'
                                }}>PAÍS</span></h2> */}
                                                            <img src={logo} className="custom-img" style={{height: '300px'}}/>

                            </div>

                            <div data-swiper-parallax="400" className="slide-text" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: '100%',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <p style={{ maxWidth: '60%', fontFamily: 'Raleway', fontWeight: 300, fontSize: '20px', textAlign: 'center' }} ><strong>"Un Solo País"</strong>  es un movimiento político liderado por Raúl Lozano, centrado en la defensa de valores tradicionales, justicia social, y unidad nacional. Su enfoque está en promover un Uruguay más seguro y próspero, con políticas que fortalezcan el respeto a las leyes, combatan la corrupción, y mejoren la calidad de vida de los ciudadanos.</p>
                                <p style={{ maxWidth: '60%', fontFamily: 'Raleway', fontWeight: 300, fontSize: '20px', textAlign: 'center' }}>Impulsamos una visión de desarrollo basada en el respeto a las instituciones y en la construcción de un país donde el bienestar colectivo sea prioridad.
</p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default Hero3;