import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    const handleScrollToSection = (e, sectionId) => {
        e.preventDefault();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => setScroll(document.documentElement.scrollTop);

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const showItems = scroll > 80;

    return (
        <header id="header">
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6">
                                <div className="navbar-header">
                                    <Link onClick={(e) => handleScrollToSection(e, 'home')} className="navbar-brand" to="/">
                                        <img src={props.Logo} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    {showItems && (
                                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                                            <li><a href="#espacio" onClick={(e) => handleScrollToSection(e, 'espacio')}>Un Solo País</a></li>
                                            <li><a href="#about" onClick={(e) => handleScrollToSection(e, 'about')}>Acerca de Rosanna</a></li>
                                            <li><a href="#propuestas" onClick={(e) => handleScrollToSection(e, 'propuestas')}>Propuestas</a></li>
                                            <li><a href="#contacto" onClick={(e) => handleScrollToSection(e, 'contacto')}>Contacto</a></li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
