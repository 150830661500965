import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Toaster, toast } from 'react-hot-toast';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
    
            // Using SMTP.js to send the email with SMTP server details
            window.Email.send({
                Host: "smtp.elasticemail.com", // Server from your screenshot
                Username: "contacto@rosanna2701.uy", // Your email address
                Password: "CADCDCDBF41C2E4007865C32D47D110F66FC", // SMTP password from your screenshot
                To: 'hola@rosanna2701.uy',
                From: 'contacto@rosanna2701.uy', // Sender's email
                Subject: `Contacto web de ${forms.name}`,
                Body: `
                    <h2>Nuevo mensaje de contacto web</h2>
                    <p><strong>Nombre:</strong> ${forms.name}</p>
                    <p><strong>Email:</strong> ${forms.email}</p>
                    <p><strong>Mensaje:</strong> ${forms.message}</p>
                `,
                Port: 2525 // The port from your screenshot
            }).then((message) => {
                if (message === 'OK') {
                    toast.success('Su consulta fue enviada correctamente. Responderemos a la brevedad.');
                    setForms({
                        name: '',
                        email: '',
                        message: ''
                    });
                } else {
                    toast.error('Ha ocurrido un error. Por favor intente nuevamente.');
                }
            }).catch((error) => {
                toast.error('Ha ocurrido un error. Por favor intente nuevamente.');
            });
        } else {
            validator.showMessages();
        }
    };
    

    return (
        <>
            <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <input
                                value={forms.name}
                                type="text"
                                style={{ fontFamily: 'Raleway' }}
                                name="name"
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                placeholder="Nombre completo" />
                            {validator.message('name', forms.name, 'required|alpha_space')}
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <input
                                value={forms.email}
                                type="email"
                                style={{ fontFamily: 'Raleway' }}
                                name="email"
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                placeholder="Correo electrónico" />
                            {validator.message('email', forms.email, 'required|email')}
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <textarea
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.message}
                            type="text"
                            style={{ fontFamily: 'Raleway' }}
                            name="message"
                            placeholder="Escriba su mensaje...">
                        </textarea>
                        {validator.message('message', forms.message, 'required')}
                    </div>
                </div>
                <div className="submit-area">
                    <button type="submit" className="theme-btn">Enviar consulta</button>
                </div>
                <Toaster   position="bottom-left"
  reverseOrder={false}/> {/* Make sure Toaster is included here */}
            </form>
        </>
    );
}

export default ContactForm;
