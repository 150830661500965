import React from "react";
import innerImg from '../../images/bannerRossana.png';

const Hero4 = () => {
    return (
        <section className="static-hero-s3">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="hero-content">
                            <div className="hero-content-wrap">
                                <div className="slide-title">
                                    <p className="wrapped-text">Valentía y gestión</p>
                                </div>
                                <div className="slide-text">
                                    <p className="special">para un Uruguay mejor.</p>
                                </div>
                                <div className="slide-video-content">
                                    <div className="slide-video-text">
                                        <p><strong>Sumate al cambio.</strong> Juntos podemos recuperar los valores y la calidad de vida que merecemos.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="politian-pic">
                                <div className="inner">
                                    <img src={innerImg} alt="Politician" />
                                </div>
                                                                <div className="back-shape"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero4;
