import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Campaign from '../../api/campaign';
import shape from '../../images/campaign/shape.png';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import pablo from '../../images/pablo.jpg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const CampaignSectionS2 = (props) => {
    const [activeAccordion, setActiveAccordion] = useState(null); // Track which accordion is open

    const toggleAccordion = (index) => {
        if (activeAccordion === index) {
            setActiveAccordion(null); // Close if it's already open
        } else {
            setActiveAccordion(index); // Open the clicked accordion
        }
    };

    return (
        <div className={`wpo-campaign-area-s2 section-padding ${props.cmClass}`} style={{ marginTop: 0, marginBottom: 0, paddingTop: '60px', background: 'rgb(245, 243, 243)' }}>
            <div style={{
                display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginBottom: '30px',
                borderRadius: '6px 6px 0px 0px',
            }}>
                <div style={{
                    margin: '10px 38px 10px 38px',
                    padding: '2px 10px',
                    backgroundColor: '#264F9A',
                    borderRadius: '6px',
                    display: 'inline-block',
                    lineHeight: '1.2',
                    fontFamily: '"Raleway", sans-serif'
                }}>
                    <h1 className="centered-title propuestas" style={{ color: 'white', marginBottom: 0, textAlign: 'left' }}>Propuestas:</h1>
                </div>
                <p style={{
                    width: '70%',
                    textAlign: 'center',
                    marginTop: '20px',
                    fontWeight: 300,
                    marginBottom: '20px',
                    marginTop: '30px',
                    fontFamily: 'Raleway',
                    fontSize: '18px'
                }}>Tenemos varias propuestas para presentarte, para eso te invitamos a descargarte el plan de Gobierno. Te dejamos a continuación un vistazo de dos grandes ejes de nuestra gestión futura. </p>
            </div>
            <div className="container">



                {/* Campaign sections */}
                <div style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    padding: '30px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    marginBottom: '20px'
                }}>


                    {Campaign.map((campaign, srv) => (
                        <div className="wpo-campaign-single" key={srv} style={{ marginBottom: '40px', borderBottom: srv !== 1 ? '1px solid #e5e5e5' : 'none' }}>
                            <div className="wpo-campaign-item" style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start', /* Align text to the top */
                                padding: '20px',
                                marginBottom: '20px'
                            }}>

                                {/* Left Column - Title and Description */}
                                <div className="wpo-campaign-content" style={{ width: '45%' }}>
                                    <div className="wpo-campaign-text-top">
                                        <small style={{ color: '#A52A2A', fontWeight: 'bold' }}>{campaign.thumb}</small>
                                        <h1 style={{
                                            color: '#264F9A',
                                            fontWeight: '700',
                                            margin: '10px 0',
                                            whiteSpace: 'pre-line'
                                        }}>{campaign.title}</h1>
                                        {srv == 0 ? (
                                            <>
                                                <p style={{ fontWeight: 300, marginBottom: '30px', marginTop: '30px', fontFamily: 'Raleway', fontSize: '16px' }}>
                                                    Detallamos algunos titulares de nuestro programa de seguridad y te invitamos a leerlo completo aquí.
                                                </p>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <div className="theme-alt-btn special-btn" style={{ fontSize: '22px' }}>
                                                        <a
                                                            href={`${process.env.PUBLIC_URL}/plan.pdf`}
                                                            download="Plan_de_Gobierno.pdf"
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                        >
                                                            <DownloadIcon fontSize="small" style={{ marginRight: '5px', marginBottom: '5px' }} />
                                                            Descargar Plan de Gobierno
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p className="special-box" style={{ fontWeight: 300, marginTop: '30px', fontFamily: 'Raleway', fontSize: '16px' }}>
                                                    Pablo Galli, primer suplemente de Rosanna, será quien liderará los temas PYMES en la agenda.
                                                </p>
                                                <p className="special-box" style={{ fontWeight: 300,  fontFamily: 'Raleway', fontSize: '16px' }}>
                                                    Con más de 43 años detrás de un mostrador, quiere llevar adelante un proyecto que ayude a quienes conforman el sistema productivo de este país. Con la lista 2701, queremos llevar un Pymes al parlamento.
                                                </p>
                                                <p className="special-box" style={{ fontWeight: 300, marginBottom: '30px',  fontFamily: 'Raleway', fontSize: '16px' }}>
                                                    Con la lista 2701, queremos llevar un Pymes al parlamento.
                                                </p>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <div className="wpo-testimonial-text-btm" style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <div style={{
                                                            padding: '6px',
                                                            background: '#fff',
                                                            borderRadius: '50%',
                                                            boxShadow: 'rgb(0 18 52 / 63%) 0px 2px 10px',
                                                            marginRight: '18px'
                                                        }}>
                                                            <img src={pablo} alt="" style={{
                                                                borderRadius: '50%',
                                                                height: '80px'
                                                            }} />
                                                        </div>
                                                        <div className="wpo-testimonial-text-btm-info">
                                                            <h3 >Pablo Galli</h3>
                                                            <span style={{ fontFamily: 'Raleway' }}>Representante PYMES 2701</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* Right Column - Accordion */}
                                <div className="accordion-section" style={{ width: '55%', marginLeft: '150px', marginTop: '80px' }}>
                                    <div className="details">
                                        {srv === 0 ? (
                                            <>
                                                {/* Accordion Section 1 for Block 1 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        color: '#8B0000',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`1-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        1. Territorialidad. Despliegue y estrategia de seguridad.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `1-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `1-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `1-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `1-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        Jefaturas, Zonas y Seccionales Policiales. Recuperar el dominio territorial urbano y rural, Crimen organizado trasnacional, lavado de activos y ciberseguridad.
                                                    </p>
                                                </div>

                                                {/* Accordion Section 2 for Block 1 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',

                                                        color: '#8B0000',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`2-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        2. Agencia Nacional de Protección.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `2-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `2-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `2-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `2-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        a) Proponer la creación de una Agencia Nacional de Protección contra el Crimen Organizado Nacional y Transnacional en la órbita del Ministerio del Interior. Tendrá la misión de asesorar, planificar, coordinar y supervisar las tareas de inteligencia, operaciones y logística en lo relativo a dichos delitos.
                                                    </p>
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        b) Brigadas Antidrogas: Organizar Brigadas Antidrogas en todas las Jefaturas Departamentales y crear las brigadas necesarias y suficientes en Canelones, San José, Montevideo y Maldonado. Se regulará el esfuerzo a fin de no generar desplazamiento negativo.
                                                    </p>
                                                </div>

                                                {/* Accordion Section 3 for Block 1 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',

                                                        color: '#8B0000',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`3-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        3. Sistema carcelario y rehabilitación.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `3-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `3-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `3-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `3-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        Proponemos una Reestructura del Sistema Carcelario a través del Instituto Nacional de Rehabilitación, estableciendo el trabajo de forma obligatoria para todas las personas privadas de libertad, de acuerdo con la ley vigente.
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* Accordion Sections for Block 2 */}
                                                {/* Accordion Section 1 for Block 2 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',

                                                        color: '#8B0000',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`1-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        1. Régimen fiscal.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `1-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `1-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `1-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `1-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        Elaboración e impulso de una fuerte reestructura del régimen fiscal de las MiPyMEs que permita reducir o mitigar el impacto de la carga impositiva.
                                                    </p>
                                                </div>

                                                {/* Accordion Section 2 for Block 2 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',

                                                        color: '#8B0000',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`2-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        2. Desarrollo Mipymes.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `2-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `2-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `2-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `2-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        Creación de políticas y programas que promuevan un entorno empresarial favorable para las MiPyMEs.
                                                    </p>
                                                </div>

                                                {/* Accordion Section 3 for Block 2 */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid rgb(38 79 154 / 20%)',

                                                        color: '#8B0000',
                                                        cursor: 'pointer',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => toggleAccordion(`3-${srv}`)}
                                                >
                                                    <strong className="accordion-title" style={{ fontFamily: 'Raleway', display: 'block', marginBottom: '10px' }}>
                                                        3. Reducir costos.
                                                    </strong>
                                                    <i className={`fa fa-chevron-${activeAccordion === `3-${srv}` ? 'up' : 'down'}`} />
                                                </div>

                                                <div className={`accordion-content ${activeAccordion === `3-${srv}` ? 'open' : ''}`}
                                                    style={{
                                                        marginBottom: '15px',
                                                        maxHeight: activeAccordion === `3-${srv}` ? '500px' : '0',
                                                        opacity: activeAccordion === `3-${srv}` ? '1' : '0',
                                                        overflow: 'hidden',
                                                        transition: 'max-height 0.4s ease, opacity 0.5s ease'
                                                    }}
                                                >
                                                    <p style={{ fontFamily: 'Raleway', fontWeight: 300, fontSize: '16px', color: '#666' }}>
                                                        Optimización de los trámites burocráticos fiscales exigidos, reduciendo el tiempo y costos de gestión.
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="shape-left">
                <img src={shape} alt="" />
            </div>
            <div className="shape-right">
                <img src={shape} alt="" />
            </div>
        </div>
    );
}

export default CampaignSectionS2;
