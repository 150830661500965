import React from 'react';
import shape from '../../images/campaign/shape.png'; // Path to your image
import DownloadIcon from '@material-ui/icons/CloudDownload';

const Donors = () => {

    return (
        <section className="wpo-donors-section">
            <div className="container full-width-container">
                <div className="wpo-donors-wrap banner-with-background">
                    <div className="row align-items-center">
                        <div style={{paddingBottom: '40px', display: 'flex', justifyContent: 'center'}}>
                            <h2 style={{
                                width: '70%', 
                                fontSize: '45px', 
                                fontFamily: 'Raleway', 
                                lineHeight: '55px',
                                textAlign: 'center'
                            }}>
                                Con la fuerza de nuestras raíces y la determinación del cambio, forjaremos el Uruguay que soñamos.
                            </h2>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="theme-alt-btn" style={{fontSize: '22px'}}>
                                <a 
                                    href="/LISTA2701.pdf" // Adjust the path based on where the PDF is located in your public folder
                                    download // Ensures it triggers a download
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <DownloadIcon fontSize="small" style={{ marginRight: '5px', marginBottom: '5px' }} />
                                    Descargar Lista
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Donors;
